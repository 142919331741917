<template>
	<div>
		<div class="about_view Main margin_auto" style="padding-bottom: 60px">
			<div class="about_img">
				<img
					src="https://zy.metaera.media/assets/images/aboutus/a16.png"
					alt=""
					class=""
				/>
			</div>
			<div class="about_content">
				<div class="about_content_left">
					<div class="about_content_title">
						<img src="https://zy.metaera.media/assets/images/aboutus/a1.png" />
						<span>关于我们</span>
					</div>
					<div class="about_content_text">
						MetaEra 成立於 2022 年，是一家全球領先的 Web 3.0
						華語資訊平台，覆蓋港澳台、東南亞、北美和中東等地區。憑借國際化的團隊和豐富的市場資源，MetaEra致力於推動
						Web 3.0 領域的信息傳播和行業發展。 MetaEra
						擁有近百萬的全網用戶，已成功舉辦了數十場具有影響力的行業峰會，並與全球知名投資機構、傑出的
						Web 3.0
						組織、行業媒體和社區建立了強大的合作聯盟。通過行業峰會、線上活動和資訊平台，MetaEra成為了傳遞行業深度觀點的重要窗口。
						期待與您合作，共同開啟 Web 3.0 的新時代！
					</div>
					<div class="about_content_slogn">
						更豐富的孵化資源<br />
						更迅捷的Web 3.0 媒體<br />
						更廣泛的全球活動影響力
					</div>
				</div>
				<div class="about_content_right">
					<div class="about_content_title">
						<img src="https://zy.metaera.media/assets/images/aboutus/a1.png" />
						<span>联系我们</span>
					</div>
					<div class="about_content_box">
						<div class="about_content_box_left">
							<div class="about_content_box_title">商务合作：</div>
							<div class="about_content_box_content">
								<img
									src="https://zy.metaera.media/assets/images/aboutus/a3.png"
								/>
								<span>WeChat：MetaEra_Media</span>
							</div>
							<div class="about_content_box_content">
								<img
									src="https://zy.metaera.media/assets/images/aboutus/a15.png"
								/>
								<span>Telegram：MetaEra_Media</span>
							</div>
						</div>
						<div class="about_content_box_right">
							<div class="about_content_box_title">活动合作：</div>
							<div
								class="about_content_box_content pointer"
								@click="sendEmail('event@metaera.hk')"
							>
								<img
									src="https://zy.metaera.media/assets/images/aboutus/a8.png"
								/>
								<span>Email: event@metaera.hk </span>
							</div>
							<div class="about_content_box_content">
								<img
									src="https://zy.metaera.media/assets/images/aboutus/a15.png"
								/>
								<span>Telegram: MetaEraEvent</span>
							</div>
						</div>
					</div>
					<div class="about_content_bottom">
						<div class="about_content_box_title">MetaEra社區：</div>
						<div class="about_content_bottom_1">
							<div
								class="about_content_bottom_1_item pointer"
								@click="showUrl('https://twitter.com/MetaEraHK')"
							>
								<img
									src="https://zy.metaera.media/assets/images/aboutus/a5.png"
								/>
								<span>Twitter</span>
							</div>
							<div
								class="about_content_bottom_1_item pointer"
								@click="showUrl('https://twitter.com/MetaEraCN')"
							>
								<img
									src="https://zy.metaera.media/assets/images/aboutus/a5.png"
								/>
								<span>Twitter中文</span>
							</div>
							<div
								class="about_content_bottom_1_item pointer"
								@click="showUrl('https://t.me/MetaEraHK')"
							>
								<img
									src="https://zy.metaera.media/assets/images/aboutus/a12.png"
								/>
								<span>Telegram</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="about_bottom">
				<div class="about_content_title">
					<img src="https://zy.metaera.media/assets/images/aboutus/a9.png" />
					<span>Logo下载</span>
				</div>
				<div class="about_bottom_1 about_bottom_1_pc">
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGO.svg')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a11.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGO.ai')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a6.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGO.eps')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a7.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGO.png')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a10.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGOW.png')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a10.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo(白)</span>
					</div>
				</div>

				<div class="about_bottom_1 about_bottom_1_mobile">
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGO.svg')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a11.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGO.ai')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a6.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
				</div>

				<div class="about_bottom_1 about_bottom_1_mobile">
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGO.eps')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a7.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGO.png')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a10.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo</span>
					</div>
				</div>

				<div class="about_bottom_1 about_bottom_1_mobile">
					<div
						class="about_bottom_1_item"
						@click="downloadFile('file/LOGOW.png')"
					>
						<img
							class="about_bottom_1_item_img1"
							src="https://zy.metaera.media/assets/images/aboutus/a10.png"
						/>
						<img
							class="about_bottom_1_item_img2"
							src="https://zy.metaera.media/assets/images/aboutus/a17.png"
						/>
						<span>MetaEra Logo(白)</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	methods: {
		downloadFile(fileName) {
			const fileUrl = "/path/to/" + fileName; // 文件的URL地址
			const link = document.createElement("a");
			link.href = fileName;
			link.setAttribute("download", fileName);
			link.click();
		},
		sendEmail(url) {
			const email = url;
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
		showUrl(url) {
			window.open(url, "");
		},
	},
};
</script>
<style scoped>
.about_view {
	width: 80%;
	background: rgba(0, 0, 0, 0);
}
.about_img {
	width: 100%;
	height: auto;
}
.about_img img {
	width: 100%;
	height: auto;
}

.about_content {
	margin-top: 64px;
	display: flex;
	justify-content: space-between;
}

.about_content_left,
.about_content_right {
	width: 45%;
}
.about_content_right {
	margin-top: 0px;
}
.about_content_title {
	display: flex;
}
.about_content_title img {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	opacity: 1;

	/* 333 */
	background: #333333;
}
.about_content_title span {
	margin-left: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: 16px;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.about_content_text {
	margin-top: 30px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.about_content_slogn {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 26px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.about_content_box {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
}

.about_content_box_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 14px;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}
.about_content_box_content {
	display: flex;
	margin-top: 25px;
}

.about_content_box_content img {
	width: 16px;
	height: 16px;
	opacity: 1;
}

.about_content_box_content span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.about_content_bottom_1 {
	display: flex;
	margin-top: 30px;
	justify-content: space-between;
}
.about_content_bottom_1_item {
	display: flex;
}
.about_content_bottom_1_item img {
	width: 20px;
	height: 20px;
	border-radius: 2px;
	opacity: 1;

	/* 333 */
	background: #333333;
}

.about_content_bottom_1_item span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}
.about_content_bottom {
	margin-top: 50px;
}
.about_bottom {
	margin-top: 80px;
	margin-bottom: 100px;
}
.about_bottom_1 {
	margin-top: 35px;
	display: flex;
	justify-content: space-between;
}

.about_bottom_1_item {
	display: flex;
	align-items: center;
}

.about_bottom_1_item span {
	margin-left: 6px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.about_bottom_1_item_img1 {
	width: 32px;
	height: 32px;
	opacity: 1;
}

.about_bottom_1_item_img2 {
	margin-left: 15px;
	width: 12px;
	height: 12px;
	opacity: 1;
}
.about_bottom_1_mobile {
	display: none;
}
.about_bottom_1_pc {
	display: flex;
}
</style>
<style scoped>
@media screen and (max-width: 800px) {
	.about_view {
		width: 80%;
		background: rgba(0, 0, 0, 0);
	}
	.about_content {
		margin-top: 64px;
		display: block;
	}

	.about_content_left,
	.about_content_right {
		width: 100%;
	}

	.about_content_right {
		margin-top: 50px;
	}

	.about_bottom_1_mobile {
		display: flex;
	}
	.about_bottom_1_pc {
		display: none;
	}
}
</style>
